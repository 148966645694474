import React from "react";
import footer_logo from "../images/logo/logo_transparent.png"



const Last = () => {
  return (
    <>
      <footer className="wide-tb-70 bg-light-gray pb-0">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 wow"
              data-wow-duration="0"
              data-wow-delay="0s"
            >
              <div className="logo-footer">
                <img src={footer_logo} alt="" />
              </div>
              <p>
              Vision Space Consult provides expert immigration, visa, and travel consultancy services with a team of highly experienced professionals, including a UK-trained lawyer. We guarantee successful visa applications and exceed client expectations.
              </p>
              <p>
              We help eliminate travel-associated fraud, making travel easier for all clients, including private, corporate, and social groups.
              </p>

              <h3 className="footer-heading">Connect</h3>
              <div className="social-icons">
                <a href="https://www.facebook.com/profile.php?id=100092495805267" target="blank">
                  <i className="icofont-facebook"></i>
                </a>
                <a href="https://www.tiktok.com/@vsconsulat" target="blank">
                <svg className="tiktok-icon" xmlns="http://www.w3.org/2000/svg" height={18} width={18} viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg>
                </a>
                <a href="https://www.linkedin.com/in/vision-space-consult-109695276/" target="blank">
                  <i className="icofont-linkedin"></i>
                </a>
                <a href="https://www.instagram.com/vision_space_consult/" target="blank">
                  <i className="icofont-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-wrap bg-navy-blue wide-tb-30">
          <div className="container">
            <div className="row text-md-left text-center">
              <div className="col-sm-12 col-md-6 copyright-links">
                <a href="about">ABOUT</a> <span>|</span>{" "}
                <a href="services">SERVICES</a> <span>|</span> <a href="contact">CONTACT</a>
              </div>
              <div className="col-sm-12 col-md-6 text-md-right text-center">
                © Vision Space Consult - 2023 <br /> All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Last;
