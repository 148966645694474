import React from "react";
import richard from "../images/clients/richard.jpg"
import aninwaa from "../images/clients/aninwaa.jpg"

const ClientTestimonial = () => {
  return (
    <div>
      <section className="wide-tb-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 wow">
              <h1 className="heading-main">
                <span>Client</span>
                TESTIMONAILS
              </h1>
            </div>
            <div className="col-sm-12 wide-tb-80 bg-fixed what-we-offer">
              <div className="container pos-rel">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <img
                      src={richard}
                      style={{ height: "400px", width: "400px" }}
                    />
                  </div>
                  <div className="col-md-6">
                    <h2 className="mb-4 fw-7 txt-blue wow">Richard Appiah</h2>
                    <p className="wow" data-wow-delay="0.2s">
                      I had the privilege of collaborating with Vision Space
                      Consult for my recent trip to the UK, where I attended a
                      graduation ceremony, and I must say that I am incredibly
                      impressed with their exceptional services. They took care
                      of every aspect of my journey, starting from the visa
                      application process all the way to arranging accommodation
                      and transportation.
                    </p>
                    <p className="wow" data-wow-delay="0.4s">
                      Their team of experts efficiently handled the complex visa
                      requirements, ensuring a seamless and hassle-free process.
                      Moreover, they went above and beyond to secure comfortable
                      accommodation in a convenient location. The attention to
                      detail and personalized customer service they provided
                      throughout the entire experience left a lasting impression
                      on me.
                    </p>
                    <p className="wow" data-wow-delay="0.4s">
                      Thanks to Vision Space Consult, my trip was truly
                      remarkable and unforgettable. I highly recommend their
                      services to anyone seeking a smooth and memorable travel
                      experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br />
            <br />

            <div className="col-sm-12 ">
              <div className="container pos-rel">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <h2 className="mb-4 fw-7 txt-blue wow">Abena Aninwaa</h2>
                    <p className="wow" data-wow-delay="0.2s">
                      As a mother yearning to see my daughter and granddaughter,
                      finding a reliable travel agency was crucial. Vision Space
                      Consult proved to be a blessing, enabling me to visit them
                      in the UK. Their exceptional services made the visa
                      process seamless, drawing my family closer together.
                    </p>
                    <p className="wow" data-wow-delay="0.4s">
                      Right from our initial contact, their professionalism and
                      attentiveness stood out. They guided me meticulously,
                      ensuring all documentation was in order for the UK visa
                      acquisition.
                    </p>
                    <p className="wow" data-wow-delay="0.4s">
                      Thanks to their unwavering support, I created cherished
                      memories, strengthening our family bond. I wholeheartedly
                      recommend Vision Space Consult for an unforgettable travel
                      experience that nurtures and deepens family connections.
                    </p>
                  </div>

                  <div className="col-md-6">
                    <img
                      src={aninwaa}
                      style={{ height: "400px", width: "400px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ClientTestimonial;
