import React from "react";
import Loader from "./Loader";
import whiteLogo from "../images/logo/white-logo.png";

const Header = () => {
  return (
    <>
      <Loader />
      <header className="fixed-top header-fullpage bordered-nav wow fadeInDown">
        <div className="top-bar-right d-flex align-items-center text-md-left">
          <div className="container px-0">
            <div className="row align-items-center">
              <div className="col d-flex">
                <div className="top-text">
                  <small className="txt-black">Address</small>
                  No.10, 2nd Daisy CL, Accra
                </div>
                <div className="top-text">
                  <small className="txt-black">Email Us</small>
                  <a href="mailto:info@visionspaceconsult.com">
                    info@visionspaceconsult.com
                  </a>
                </div>
                <div className="top-text">
                  <small className="txt-black">Phone Number</small>
                  032 - 200 - 9341
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg bg-transparent">
          <div className="container text-nowrap bdr-nav px-0">
            <div className="d-flex mr-auto">
              <a className="navbar-brand rounded-bottom light-bg" href="home">
                <img src={whiteLogo} alt="Logo" height="50px" width="120" />
              </a>
            </div>

            <button
              className="navbar-toggler x collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarCollapse"
              data-hover="dropdown"
              data-animations="slideInUp slideInUp slideInUp slideInUp"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="about">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="services">
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="contact">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
