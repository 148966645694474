import React from "react";
import uk from "../images/destinations/uk.jpg"
import usa from "../images/destinations/usa.jpg"
import schengen from "../images/destinations/schengen.jpg"
import canada from "../images/destinations/canada.jpg"
import other from "../images/destinations/bali.jpg"

const TravelDestinations = () => {
  return (
    <>
      <div className="offers_area">
        <div className="container">

          <div className="row">
            <div className="col-xl-12">
              <div className="section_title text-center mb-100 heading-main">
                <span>Some Of The Places We Travel To</span>
                <h1>TRAVEL DESTINATIONS </h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4 col-md-4">
              <div className="single_offers">
                <div className="about_thumb">
                  <img src={uk} alt="" />
                </div>
                <h3 className="text-center">United Kingdom</h3>
                <br />
                <p>
                Uncover the timeless allure of the United Kingdom. Immerse yourself in rich history, vibrant cities, picturesque countryside, and a tapestry of cultures that will leave you enchanted and inspired.
                </p>
              </div>
            </div>

            <div className="col-xl-4 col-md-4">
              <div className="single_offers">
                <div className="about_thumb">
                  <img src={usa} alt="" />
                </div>
                <h3 className="text-center">USA</h3>
                <br />
                <p>
                Experience the heartbeat of America in the land of endless possibilities. From the sun-kissed beaches of California to the bustling streets of New Orleans, let the USA ignite your sense of adventure.
                </p>
              </div>
            </div>

            <div className="col-xl-4 col-md-4">
              <div className="single_offers">
                <div className="about_thumb">
                  <img src={schengen} alt="" />
                </div>
                <h3 className="text-center">Schengen</h3>
                <br />
                <p>
                Unlock the treasures of the Schengen region, where historic wonders, cultural delights, and picturesque landscapes converge. Embark on a seamless journey through diverse countries, creating memories to last a lifetime.
                </p>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-xl-6 col-md-4">
              <div className="single_offers">
                <div className="about_thumb_large">
                  <img src={canada} alt="" />
                </div>
                <h3 className="text-center">Canada</h3>
                <br />
                <p>
                Immerse yourself in the breathtaking beauty of Canada, a land of majestic mountains, pristine lakes, and captivating wildlife. From vibrant cities to stunning national parks, Canada offers endless adventures for every traveler.
                </p>
              </div>
            </div>

            <div className="col-xl-6 col-md-4">
              <div className="single_offers">
                <div className="about_thumb_large">
                  <img src={other} alt="" />
                </div>
                <h3 className="text-center">Other Major World Cities</h3>
                <br />
                <p>
                Embark on a global expedition to the most extraordinary cities. Lose yourself in the bustling energy of New York, wander through the ancient streets of Rome, and let the enchantment of Paris and other cities around the world mesmerize your senses. 
                </p>
              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  );
};

export default TravelDestinations;
