import React from "react";
import Slider from "./Slilder";
import Content from "./Content";


const PageContent = () => {
	return (
		<>
			<Slider />
			<Content />
		</>
	);
};
export default PageContent;
