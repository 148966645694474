import React from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import Home from './home'
import About from './about'
import Services from './services/services'
import Contact from './contact/contact'



const SiteRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' exact={true} element={<Home />} />
        <Route path='/home' exact={true} element={<Home />} />
        <Route path='/about' exact={true} element={<About />} />
        <Route path='/services' exact={true} element={<Services />} />
        <Route path='/contact' exact={true} element={<Contact />} />
      </Routes>
    </Router>
  )
}

export default SiteRouter