import React from "react";
import Loader from "./Loader";
import normalLogo from "../images/logo/logo_transparent.png"

const Header = () => {
    return (
        <>
            <Loader />
            <header className="fixed-top header-fullpage top-border top-transparent wow fadeInDown">              

                <nav className="navbar navbar-expand-lg bg-transparent">
                    <div className="container text-nowrap">
                        <div className="d-flex align-items-center w-100 col p-0">
                            <a className="navbar-brand rounded-bottom light-bg" href="home">
                                <img src={normalLogo} alt="" />
                            </a>
                        </div>
                       
                        <button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</button>
                        
                        <div className="collapse navbar-collapse" id="navbarCollapse" data-hover="dropdown" data-animations="slideInUp slideInUp slideInUp slideInUp">
                            <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                    <a className="nav-link" href="home">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="about">About Us</a>
                                </li>                                
                                <li className="nav-item">
                                    <a className="nav-link" href="services">Services</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="contact">Contact Us</a>
                                </li>                             
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Header;
