import React from "react";
import ContactForm from "../../ContactForm";

const Content = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80 contact-full-shadow">
                    <div className="container">
                        <div className="contact-map-bg">
                            <img src="images/map-bg.png" alt="" />
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-md-6 col-sm-12 col-lg-4 wow">
                                <div className="contact-detail-shadow">
                                    <h4>Accra</h4>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-google-map"></i> <span>10 2nd Daisy CL, Accra</span>
                                    </div>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-phone"></i> <span>032 - 200 - 9341</span>
                                    </div>
                                    <div className="text-nowrap d-flex align-items-start items">
                                        <i className="icofont-email"></i> <a href="mailto:info@visionspaceconsult.com">info@visionspaceconsult.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-4 wow">
                                <div className="contact-detail-shadow">
                                    <h4>Kumasi</h4>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-google-map"></i> <span> AK - 370 - 2512</span>
                                    </div>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-phone"></i> <span>+233 20 741 - 4404</span>
                                    </div>
                                    <div className="text-nowrap d-flex align-items-start items">
                                        <i className="icofont-email"></i> <a href="mailto:info@visionspaceconsult.com">info@visionspaceconsult.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ContactForm />

            </main>
        </>
    );
};
export default Content;
