import React from "react";
import Interested from "../Interested";
import TravelDestinations from "../TravelDestinations";
import abena from "../../images/team/abena.jpg"
import richmond from "../../images/team/richmond.jpg"
import ernest from "../../images/team/ernest.jpg"

const Content = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-12 wow" data-wow-duration="0" data-wow-delay="0s">
                                <h2 className="mb-4 fw-7 txt-blue">
                                    About <span className="fw-6 txt-orange">Vision Space</span>
                                </h2>
                                <p>Vision Space consult is a Ghanaian based consult with an international presence. We are a
                                    team of highly experienced and trained professionals with a deep understanding of immigration
                                    laws, VISA applications and travel consultancy. A member of our team of experts is a UK trained
                                    lawyer in immigration and family related cases. Our ability to guarantee successful VISA
                                    applications and advise you on what exactly you need to do to achieve your travel goals is what
                                    sets us apart from the rest. We are a niche business and pride ourselves with achieving and
                                    exceeding client’s expectations. </p>
                                <p>The sweat, hustle and fraud associated with traveling to other countries ends at the doorstep of
                                    Vision Space Consult. We have arrived to put your mind at ease. Lay your travel burdens on us,
                                    take a seat and watch us deliver results!
                                    We attract all calibre of clients : private, corporate and social groups</p>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-12 wow" data-wow-duration="0" data-wow-delay="0s">
                                <h2 className="mb-4 fw-7 txt-blue">
                                    Why <span className="fw-6 txt-orange">Choose Us</span>
                                </h2>
                                <p>Our team combines over a decade of experience in VISA applications, immigration laws and
                                    travel consultancy. Our success rate in delivering expected results is unmatched. We have
                                    brilliant legal minds who understand the legal jargons and requirements for acquiring any visa
                                    category for major cities and destinations around the world. We are proactive and very
                                    professional. We treat our clients with utmost respect and patience. We deliver our services
                                    promptly and with no excuses. </p>
                                <p>We provide our services at the highest quality and offer unbeatable fees for our services. You
                                    get value for money with us.</p>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="wide-tb-100 pb-0 team-section-bottom pos-rel">
                    <div className="container">
                        <div className="col-sm-12">
                            <h1 className="heading-main">
                                Our Team
                            </h1>
                        </div>
                        <div className="row pb-4">
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.1s">
                                <div className="team-section-two">
                                    <img src={richmond} alt="" className="rounded" />
                                    <h4 className="h4-md txt-orange">Richmond Yeboah</h4>
                                    <h5 className="h5-md txt-ligt-gray">General Manager</h5>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                <div className="team-section-two">
                                    <img src={ernest} alt="" className="rounded" />
                                    <h4 className="h4-md txt-orange">Ernest Frimpong</h4>
                                    <h5 className="h5-md txt-ligt-gray">Public Relations Officer</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <TravelDestinations />

                <Interested />

               
            </main>
        </>
    );
};
export default Content;
