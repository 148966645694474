import React from "react";
import ContactForm from "../../ContactForm";
import TravelDestinations from "../../TravelDestinations";
import visa_solutions from "../../../images/visa-solutions.jpg"
import flight_reservation from "../../../images/flight-reservation.jpg"
import passport_acq from "../../../images/passport-acq.jpg"
import choose_us from "../../../images/choose-us.png"

const Content = () => {
    return (
        <>
            <main id="body-content">
                <section className="contact-callout bg-orange wide-tb-70 mb-spacer-md">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-4 col-sm-6">
                                <div className="row align-items-start">
                                    <div className="col-sm-auto col-2 text-center">
                                        <i className="icofont-wall-clock icofont-2x"></i>
                                    </div>
                                    <div className="col pl-0">
                                        <h5 className="mb-3 h5-xs fw-6">OPENING HOURS</h5>
                                        <div className="text">
                                            Monday - Friday 08.30am - 05.00pm<br />
                                            Saturday 09.00am - 03.00pm
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 col-sm-6">
                                <div className="row align-items-start">
                                    <div className="col-sm-auto col-2 text-center">
                                        <i className="icofont-phone icofont-2x"></i>
                                    </div>
                                    <div className="col pl-0">
                                        <h5 className="mb-3 h5-xs fw-6">CALL US ANYTIME</h5>
                                        <div className="text">
                                            +233 (0) 20 741 4404 <br />
                                            +233 (0) 55 419 9455
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>

                            <div className="col-12 col-lg-4 col-sm-6">
                                <div className="row align-items-start">
                                    <div className="col-sm-auto col-2 text-center">
                                        <i className="icofont-envelope icofont-2x"></i>
                                    </div>
                                    <div className="col pl-0">
                                        <h5 className="mb-3 h5-xs fw-6">EMAIL US</h5>
                                        <div className="text">
                                            <a href="mailto:info@visionspaceconsult.com" className="link-light">info@visionspaceconsult.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

                <section className="bg-white wide-tb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 wow" >
                                <h1 className="heading-main">
                                    <span>WELCOME TO</span>
                                    Vision Space Consult
                                </h1>
                            </div>

                            <div className="col-md-4 wow" >
                                <a href="services">
                                    <div className="icon-box-1">
                                        <img src={visa_solutions} alt="visit" />
                                        <div className="text">
                                            VISA SOLUTIONS
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-4 wow">
                                <a href="services">
                                    <div className="icon-box-1">
                                        <img src={flight_reservation} alt="" />
                                        <div className="text">
                                            FLIGHT RESERVATION
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-4 wow">
                                <a href="services">
                                    <div className="icon-box-1">
                                        <img src={passport_acq} alt="pss" />
                                        <div className="text">
                                            PASSPORT ACQUISITION
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>

                <TravelDestinations />

                <section className="bg-light-gray wide-tb-100 bg-wave">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="img-business-man">
                                <img src={choose_us} height='640' width='400' alt="" />
                            </div>
                            <div className="col-sm-12 wow" >
                                <h1 className="heading-main">
                                    Why Choose Us
                                </h1>
                            </div>
                            <div className="col-md-8 ml-auto why-choose wow">
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-id"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md heading-sub">Experience & Expertise</h4>
                                                    <p>Our team brings over a decade of experience in visa applications, immigration laws, and travel consultancy.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-live-support"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md heading-sub">Professionalism and Respect</h4>
                                                    <p>We value proactiveness. We are highy proactive, very professional, and treat clients with utmost respect and patience.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 spacer-60 d-none d-md-none d-lg-block d-sm-none"></div>

                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-history"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md heading-sub">Success & Results</h4>
                                                    <p>Our success rate in delivering expected results is unmatched. We know how to navigate legal jargon and requirements to acquire visas for major cities and destinations.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-briefcase-1"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md heading-sub">High Quality and Affordable</h4>
                                                    <p>We provide our services at the highest quality and offer unbeatable fees for each of our services, providing clients with value for money.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </section>



                <ContactForm />






                {/*<section className="map-bg">
                    <div className="contact-details row d-flex">
                        <div className="col">
                            <h4>Germany</h4>
                            <p><i className="icofont-phone"></i> +1 (408) 786 - 5117</p>
                            <div className="text-nowrap"><i className="icofont-email"></i> <a href="#">germany@logzee.com</a></div>
                        </div>
                        <div className="col">
                            <h4>Spain</h4>
                            <p><i className="icofont-phone"></i> +1 (408) 786 - 5117</p>
                            <div className="text-nowrap"><i className="icofont-email"></i> <a href="#">spain@logzee.com</a></div>
                        </div>
                    </div>
                    <div id="map-holder" className="pos-rel">
                        <div id="map_extended">
                            <p>This will be replaced with the Google Map.</p>
                        </div>
                    </div>
    </section>*/}
            </main>
        </>
    );
};
export default Content;
