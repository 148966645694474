import React from 'react';


 const ContactForm = () => {
  
  return (
    <>
      <section className="wide-tb-100 bg-fixed free-quote pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-7">
              <div className="free-quote-form">
                <h1 className="heading-main mb-4">
                  <span>Send Us </span>A Message
                </h1>

                <form
                  
                  id="form"
                  action="#"
                  method="post"
                  noValidate="novalidate"
                  className="col rounded-field"
                >
                  <div className="form-row mb-4">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="form-row mb-4">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-row mb-4">
                    <select
                      title="Please select a service"
                      required=""
                      name="package"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                    >
                      <option value="">Type of Service</option>
                      <option value="Type 1">Passport acquisition</option>
                      <option value="Type 2">Hotel / Flight Reservation</option>
                      <option value="Type 3">Student Visa</option>
                      <option value="Type 4">
                        School Applications, Personal Statements and CV Writing
                      </option>
                      <option value="Type 5">Visit/ Tourist visas</option>
                      <option value="Type 6">Spouse/ partner visas</option>
                      <option value="Type 7">Work permits visas</option>
                      <option value="Type 8">
                        Reunion with family members visas
                      </option>
                      <option value="Type 9">
                        Advice On Settlement and Relocation Options
                      </option>
                      <option value="Type 10">Other</option>
                    </select>
                  </div>
                  <div className="form-row mb-4">
                    <textarea
                      name="message"
                      rows="7"
                      placeholder="Message"
                      className="form-control"
                    ></textarea>
                  </div>
                  <div className="form-row text-center">
                    <button
                      type="submit" id="button"
                      value="Send Email"
                      className="form-btn mx-auto btn-theme bg-orange"
                    >
                      Submit Now <i className="icofont-rounded-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
