import React from 'react'

const Interested = () => {
  return (
    <>
       <section className="wide-tb-80 bg-navy-blue callout-style-1 wow" data-wow-duration="0" data-wow-delay="0s">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12 mb-0">
                                <h4 className="h4-xl">Interested in working with Vision Space?</h4>
                            </div>
                            <div className="col">
                                <div className="center-text">
                                We don't just offer immigration, VISA and travel consultancy, we guarantee successful applications and exceed expectations.
                                            </div>
                            </div>
                            <div className="col-sm-auto">
                                <a href="contact" className="btn-theme bg-white light">Get In Touch <i className="icofont-rounded-right"></i></a>
                            </div>
                        </div>
                    </div>
                </section> 
    </>
  )
}

export default Interested