import React from "react";
import TravelDestinations from "../../TravelDestinations";
import Interested from "../../Interested";
import ClientTestimonial from "../../ClientTestimonial";
import services_overview from "../../../images/services/services-overview.jpg"
import box1 from "../../../images/services/icon-box-1.jpg"
import box2 from "../../../images/services/icon-box-2.jpg"
import box3 from "../../../images/services/icon-box-3.jpg"
import box4 from "../../../images/services/icon-box-4.jpg"
import box5 from "../../../images/services/icon-box-5.jpg"
import box6 from "../../../images/services/icon-box-6.jpg"
import box7 from "../../../images/services/icon-box-7.jpg"
import box8 from "../../../images/services/icon-box-8.jpg"
import box9 from "../../../images/services/icon-box-9.jpg"

const Services = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80 bg-fixed what-we-offer">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mb-4 fw-7 txt-blue wow">
                                    Expert Guidance <br /><span className="fw-6 txt-orange">for Seamless Travel</span>
                                </h2>
                                <p className="wow">We are a leading consultancy firm in Ghana, specializing in immigration laws, visa applications, and travel consultancy. Our experienced team of professionals offer a personalized and proactive approach to visa acquisition and related services.</p>
                                <p className="wow">With a focus on quality, performance and affordability, we provide exceptional services, delivering exceptional value for money and exceeding client expectations.</p>
                            </div>
                            <div className="col-md-6">
                                <img src={services_overview} alt="visit" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-white wide-tb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 wow">
                                <h1 className="heading-main">
                                    Our Services
                                </h1>
                            </div>
                            <div className="col-md-4 wow">

                                <div className="icon-box-1">
                                    <img src={box1} alt="" />
                                    <div className="text">
                                        PASSPORT ACQUISITION
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 wow">

                                <div className="icon-box-1">
                                    <img src={box2} alt="" />
                                    <div className="text">
                                        HOTEL AND FLIGHT RESERVATIONS
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 wow">

                                <div className="icon-box-1">
                                    <img src={box3} alt="" />
                                    <div className="text">
                                        STUDENT VISAS
                                    </div>
                                </div>

                            </div>

                        </div>

                        <br />
                        <div className="row">
                            <div className="col-md-4 wow">

                                <div className="icon-box-1">
                                    <img src={box4} alt="" />
                                    <div className="text">
                                        SCHOOL APLLICATIONS, SOP & CV
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 wow">

                                <div className="icon-box-1">
                                    <img src={box5} alt="" />
                                    <div className="text">
                                        VISIT / TOURIST VISA
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 wow">

                                <div className="icon-box-1">
                                    <img src={box6} alt="" />
                                    <div className="text">
                                        SPOUSE / PARTNER VISA
                                    </div>
                                </div>

                            </div>

                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-4 wow">

                                <div className="icon-box-1">
                                    <img src={box7} alt="" />
                                    <div className="text">
                                        WORK PERMIT VISA
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 wow">

                                <div className="icon-box-1">
                                    <img src={box8} alt="" />
                                    <div className="text">
                                        FAMILY REUNION VISA
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 wow">

                                <div className="icon-box-1">
                                    <img src={box9} alt="" />
                                    <div className="text">
                                        SETTLEMENT ADVICE AFTER RELOCATION
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>

                <TravelDestinations />

                <ClientTestimonial />

                <Interested />

            </main>
        </>
    );
};
export default Services;
